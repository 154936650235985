import React, { forwardRef, useImperativeHandle } from "react";

import Loadable from "react-loadable";

import { MassiveUserDataProps } from "~/utils/interfaces/Flatfile";

const LoadingComponent = (props: any) => {
  return <div>Loading...</div>;
};

const LoadableComponent = Loadable({
  loader: async () => import("./MassiveUserDataMx"),
  loading: LoadingComponent,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  },
});

const MassiveUserDataLoader = forwardRef(function MassiveUserDataLoader(
  props: MassiveUserDataProps,
  ref: any,
): JSX.Element {
  const validate = (): string[] => {
    const errors: string[] = [];
    if (props.sheetErrors.length) {
      errors.push("Hay errores con los datos ingresados al excel. Favor revisarlos nuevamente.");
    }
    return errors;
  };

  useImperativeHandle(ref, () => ({
    getState: () => {
      return [...props.massiveUserData];
    },
    validate: validate,
  }));
  return <LoadableComponent {...props} />;
});

export default MassiveUserDataLoader;
