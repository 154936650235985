import React, { useEffect, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { navigate } from "gatsby";
import moment from "moment";
import Swal from "sweetalert2";

import { Container, Errors } from "~/assets/styles/Offliners";
import MassiveUserDataMx from "~/components/Appointments/MassiveUserDataLoaderMx";
import AppointmentData from "~/components/Appointments/Offline/AppointmentData";
import DiscountCodeApplier from "~/components/Appointments/Offline/DiscountCodeApplier";
import NurseAndLabServices from "~/components/Appointments/Offline/NurseAndLabServices";
import SalesSourceSelector from "~/components/Appointments/Offline/SalesSourceSelector";
import UserData from "~/components/Appointments/UserData";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import Flex from "~/components/Containers/Flex";
import LoadingError from "~/components/Loaders/LoadingError";
import { useScrollTop } from "~/hooks";
import useAddessValue from "~/hooks/useAddressValue";
import alliancesService from "~/utils/api/v1/alliancesService";
import appointmentService from "~/utils/api/v1/appointmentService";
import { fetchHelper } from "~/utils/api/v1/fetchHelper";
import { CartItemObjectType } from "~/utils/interfaces/cartItem";
import { SheetError } from "~/utils/interfaces/Flatfile";
import { IDoctorData } from "~/utils/interfaces/Funnel";
import SelectedService from "~/utils/interfaces/LabServices";
import { User } from "~/utils/interfaces/User";
import { getUserServicesOrPacks } from "~/utils/offliner";
import handleTaskSwal from "~/utils/tasks";

const OfflineAppointmentMxCreate = (): JSX.Element => {
  const [error, setError] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [additionalCharge, setAdditionalCharge] = useState<string>("");
  const [isMassiveUpload, setIsMassiveUpload] = useState<boolean>(false);
  const [isOperative, setIsOperative] = useState<boolean>(false);
  const [massiveUserData, setMassiveUserData] = useState<User[]>([]);
  const [sheetErrors, setSheetErrors] = useState<SheetError[]>([]);
  const [salesSource, setSalesSource] = useState<string>("marketplace");
  const [origins, setOrigins] = useState<string[]>([]);
  const [selectedOrigin, setSelectedOrigin] = useState<string>("no");
  const [inputOrigin, setInputOrigin] = useState<string>("");
  const [originLoading, setOriginLoading] = useState<boolean>(false);
  const appointmentDataRef = useRef<any>(null);
  const nurseAndLabServicesRef = useRef<any>(null);
  const userDataRef = useRef<any>(null);
  const discountCodeRef = useRef<any>(null);
  const doctorRef = useRef<Array<IDoctorData | null>>([null]);

  const { clear } = useAddessValue();

  const handleSubmit = async () => {
    // check validations
    let vErrors: string[] = [];
    vErrors = vErrors.concat(appointmentDataRef.current.validate());
    vErrors = vErrors.concat(nurseAndLabServicesRef.current.validate());
    vErrors = vErrors.concat(userDataRef.current.validate());

    if (
      doctorRef.current.some((doctor: IDoctorData | null) => {
        if (!doctor) return false;
        return !doctor.id && !doctor.name;
      })
    ) {
      vErrors.push("Cada doctor debe tener ID o nombre");
    }

    if (selectedOrigin === "Other" && inputOrigin === "") {
      vErrors.push("Debes incluir un origen o seleccionar '-'");
    }

    if (vErrors.length > 0) {
      setValidationErrors(vErrors);
      return;
    } else {
      setValidationErrors([]);
    }

    useScrollTop();

    const appointmentData = appointmentDataRef.current.getState();
    const nurseAndLabServices = nurseAndLabServicesRef.current.getState();
    const userDataState = userDataRef.current.getState();
    const userData = isMassiveUpload ? userDataState : JSON.parse(JSON.stringify(userDataState.userData));
    const doctorData = isMassiveUpload
      ? Array(userData.length).fill(doctorRef.current[0])
      : doctorRef.current.map((doctor: IDoctorData | null) => {
          if (!doctor) return null;
          if (!doctor.id) {
            return {
              ...doctor,
              id: doctor.name,
            };
          }
          return doctor;
        });
    const discountData = discountCodeRef.current.getState();
    const allServices = nurseAndLabServices.selectedItems.map((service: SelectedService) => ({
      id: service.id,
      amount: 1,
      fonasa: false,
    }));

    const userServices = isMassiveUpload ? userData.map(() => allServices) : getUserServicesOrPacks(userDataState);
    const userPacks = getUserServicesOrPacks(userDataState, true);
    // fix user data date of birth format
    for (let i = 0; i < userData.length; i++) {
      const formatted = moment(userData[i].date_of_birth, "YYYY-MM-DD").format("YYYY-MM-DDT12:00:00+00");
      userData[i].date_of_birth = formatted;
    }

    // check if any patient has no service attached
    // if not massive, check whether there is no user services, any is empty, or lengths are mismatched
    if (!isMassiveUpload) {
      const allUserServices: CartItemObjectType[][] = userDataState.userServices;
      if (
        !allUserServices.length ||
        userData.length !== allUserServices.length ||
        allUserServices.some((user: CartItemObjectType[]) => !user.length)
      ) {
        vErrors = vErrors.concat(["Debe agendar exámenes para todos los pacientes"]);
      }
    }

    if (vErrors.length > 0) {
      setValidationErrors(vErrors);
      useScrollTop();
      return;
    } else {
      setValidationErrors([]);
    }

    setLoading(true);
    try {
      const timeblocks = appointmentData.selectedTimeblocks as string[];
      const initTimeblock = timeblocks[0].split("-")[0];
      const finishTimeblock = timeblocks[timeblocks.length - 1].split("-")[1];
      const formatedDate = appointmentData.date.format("YYYY-MM-DD");
      let finalOrigin: string | null = null;
      if (selectedOrigin !== "no") {
        finalOrigin = selectedOrigin !== "Other" ? selectedOrigin : inputOrigin;
      }
      const newAppointment = {
        user_data: userData,
        user_services: userServices,
        user_packs: userPacks,
        doctors: doctorData,
        appointment_data: {
          is_operative: appointmentData.isOperative,
          managing_email: appointmentData.managingEmail,
          target_address: `${appointmentData.address}, ${appointmentData.comuna}, ${appointmentData.region}`,
          target_address_additional_info: appointmentData.additionalInfo,
          target_lat: appointmentData.coordinates.lat,
          target_lng: appointmentData.coordinates.lng,
          status: appointmentData.isFinished ? "finished" : "confirmed",
          contact_channel: appointmentData.selectedContactChannel
            ? (appointmentData.selectedContactChannel as string).toLowerCase()
            : undefined,
          target_residence_type: appointmentData.isApartment ? "apartment" : "house",
          begin_date: `${formatedDate} ${initTimeblock}`,
          end_date: `${formatedDate} ${finishTimeblock}`,
          comuna: appointmentData.comuna,
          region: appointmentData.region,
          country: appointmentData.country,
          address_line_1: appointmentData.address,
          is_factura: appointmentData.isFactura,
          finished: appointmentData.isFinished,
          operative_data: appointmentData.operativeData,
          referral_code: discountData.discountCodeValidated ? discountData.discountCode : null,
          blocks_needed: timeblocks.length,
          sales_source: salesSource,
          client_origin: finalOrigin,
          is_polygon_tester: true,
        },
        offline_payment: appointmentData.isOfflinePayment,
        folio: appointmentData.folio,
        nurses: nurseAndLabServices.selectedNurses,
        additional_charge: +additionalCharge,
        is_massive_upload: isMassiveUpload,
      };

      if (appointmentData.isApartment) {
        // @ts-expect-error
        newAppointment.appointment_data.target_apartment_number = appointmentData.aptNumber;
      }
      const req = await appointmentService.createOffliner(newAppointment);
      if (req.status === 202) {
        Swal.fire({
          title: "En espera",
          text: "La subida está en espera del servidor. Por favor espere.",
          icon: "info",
          showConfirmButton: false,
        }).then((result) => {
          if (result.isDenied) {
            navigate("/massive_upload");
          }
        });
        handleTaskSwal(req.data.id);
      } else {
        throw Error("Error subiendo la cita");
      }
    } catch (err) {
      setError(err);
      console.log(err);
      await Swal.fire({
        title: "Whoops, something went wrong!",
        text: err.message,
        icon: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchClientOrigins = async () => {
      await fetchHelper(setOriginLoading, async () => {
        const res = await alliancesService.fetchClientOrigins(salesSource);
        setOrigins(res.data.origins);
        setSelectedOrigin("no");
        setInputOrigin("");
      });
    };

    fetchClientOrigins();
  }, [salesSource]);

  useEffect(() => {
    clear();
  }, []);

  return (
    <PrivateRoute>
      <Container variant="outlined">
        <LoadingError
          loading={loading}
          error={error}
        />
        <Flex
          justify="center"
          margin="1rem 0rem 3rem 0rem"
        >
          <Flex
            direction="column"
            justify="center"
          >
            <h1>
              Crear <i>offline appointment</i>
            </h1>
            <br />
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                style={{ textAlign: "right" }}
              >
                <Typography>Carga simple</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ textAlign: "center" }}
              >
                <Switch
                  color="primary"
                  value={isMassiveUpload}
                  onChange={(e) => setIsMassiveUpload(e.target.checked as boolean)}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Typography>Carga masiva</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                style={{ textAlign: "right" }}
              >
                <Typography>Agenda Normal</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ textAlign: "center" }}
              >
                <Switch
                  color="primary"
                  checked={isOperative}
                  onChange={(e) => setIsOperative(e.target.checked as boolean)}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Typography>Operativo</Typography>
              </Grid>
            </Grid>
          </Flex>
        </Flex>
        {validationErrors.length > 0 && (
          <Errors>
            <ul>
              {validationErrors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </Errors>
        )}
        <AppointmentData
          askPaymentMethod
          ref={appointmentDataRef}
          setError={(err) => {
            setError(err);
          }}
          country="México"
          isOperative={isOperative}
        />
        <NurseAndLabServices
          ref={nurseAndLabServicesRef}
          setError={(err) => {
            setError(err);
          }}
          isMassiveUpload={isMassiveUpload}
          allowMultipleNurses={isOperative}
          locale="mx"
          doctorRef={doctorRef}
          allDoctors={[]}
          salesSource={salesSource}
        />
        <SalesSourceSelector
          setValue={setSalesSource}
          currentValue={salesSource}
        />
        {isMassiveUpload && (
          <MassiveUserDataMx
            ref={userDataRef}
            setLoading={setLoading}
            setError={(err) => setError(err)}
            setSheetErrors={setSheetErrors}
            sheetErrors={sheetErrors}
            setMassiveUserData={setMassiveUserData}
            massiveUserData={massiveUserData}
          />
        )}
        {!isMassiveUpload && (
          <UserData
            ref={userDataRef}
            setError={(err) => {
              setError(err);
            }}
            locale="mx"
            doctorRef={doctorRef}
            allDoctors={[]}
            salesSource={salesSource}
          />
        )}
        <Flex
          justify="center"
          gap="1rem"
        >
          <DiscountCodeApplier
            isMassiveUpload={isMassiveUpload}
            nurseAndLabServicesRef={nurseAndLabServicesRef}
            usersRef={userDataRef}
            ref={discountCodeRef}
          />
          <Flex
            direction="column"
            justify="flex-end"
            margin="0 0 2.5rem 0"
          >
            <InputLabel id="changeSalesSource">Client Origin</InputLabel>
            <Select
              labelId="change-sales-source"
              id="change-sales-soruce"
              label="Sales Source"
              value={selectedOrigin}
              onChange={(e) => {
                setSelectedOrigin(e.target.value as string);
              }}
            >
              {origins.map((origin) => {
                return (
                  <MenuItem
                    key={origin}
                    value={origin}
                  >
                    {origin}
                  </MenuItem>
                );
              })}
              <MenuItem
                key="Other"
                value="Other"
              >
                Otro
              </MenuItem>
              <MenuItem
                key="no"
                value="no"
              >
                -
              </MenuItem>
            </Select>
          </Flex>
          {selectedOrigin === "Other" && (
            <Flex
              direction="column"
              justify="flex-end"
              margin="0 0 2.5rem 0"
            >
              <TextField
                value={inputOrigin}
                onChange={(e) => {
                  setInputOrigin(e.target.value as string);
                }}
                placeholder="Origen"
              />
            </Flex>
          )}
        </Flex>
        <Flex
          justify="right"
          align="center"
          margin="1rem 0rem"
        >
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={loading}
          >
            Crear
          </Button>
        </Flex>
      </Container>
    </PrivateRoute>
  );
};

export default OfflineAppointmentMxCreate;
